import '../main.css' 

// Menu
import MENU from './modules/menu';
const menu = new MENU();

// Dropdown
import DROPDOWN from './modules/dropdown';
const dropdown = new DROPDOWN();

// Slider
import SLIDER from './modules/sliders';
const slider = new SLIDER();

// Same Height
import SAMEHEIGHT from './modules/height';
const sameHeight = new SAMEHEIGHT();

// Login  
import LOGIN from './modules/login';
const login = new LOGIN();

// Filters
import FILTERS from './modules/filters';
const filters = new FILTERS();

// AOS
import AOS from './modules/aos';
const aos = new AOS();

// Fancyapps
import FANCYAPPS from './modules/fancyapps';
const fancyapps = new FANCYAPPS();

// Selects
import SELECTS from './modules/selects';
const selects = new SELECTS();
