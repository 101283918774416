export default class Selects {
  constructor() {
    if (
      document.getElementById("filter-month") &&
      document.getElementById("filter-year")
    ) {
      document
        .getElementById("filter-month")
        .addEventListener("change", function () {
          document.getElementById("filter-form").submit();
        });

      document
        .getElementById("filter-year")
        .addEventListener("change", function () {
          document.getElementById("filter-form").submit();
        });
    }
  }
}
