import $ from 'jquery';

export default class Filters {
  constructor() {
    this.facetWpTemplate = document.querySelectorAll('facetwp-template');
    this.moreFiltersButton = document.getElementById('moreFilters');
    this.moreFiltersButttonMobile = document.getElementById('moreFiltersMobile');
    this.moreFiltersContainer = document.getElementsByClassName('overlay');
    this.moreFiltersCloseButton = document.getElementsByClassName('closeFilters');
    this.moreFiltersCloseButtonApply = document.getElementsByClassName('apply-facet');
    this.resetButton();
    this.events();
  }

  // Events
  events() {
    const toggleFilters = (e) => {
      e.preventDefault();
      this.moreFiltersContainer[0]?.classList.toggle('overlay--active');
    };

    this.moreFiltersButton?.addEventListener('click', toggleFilters);
    this.moreFiltersCloseButton[0]?.addEventListener('click', toggleFilters);
    this.moreFiltersCloseButtonApply[0]?.addEventListener('click', toggleFilters);
    this.moreFiltersButttonMobile?.addEventListener('click', () => {
      this.moreFiltersContainer[0]?.classList.add('overlay--active');
    });

    // Handle click outside the div
    if (window.matchMedia("(min-width: 768px)").matches) {
      document.addEventListener('click', (e) => {
        const target = e.target;
        if (!target.closest('.overlay') && !target.closest('#moreFilters')) {
          this.moreFiltersContainer[0]?.classList.remove('overlay--active');
        }
      });
    }
  }

  resetButton() {
    $(document).on('facetwp-loaded', () => {
      const qs = FWP.buildQueryString();
      if ('' === qs) {
        $('.reset-facet').hide();
      } else {
        $('.reset-facet').show();
      }
    });
  }
}
