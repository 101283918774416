import $ from 'jquery';
import slick from 'slick-carousel';

export default class Slider {

  constructor() {
    this.abiqSlider = $('.abiq-slider__wrapper');
    this.abiqSliderIndustries = $('.industries__wrapper__slider');
    this.abiqSliderAssocietes = $('.associetes__wrapper__slider');
    this.abiqSliderRecipes = $('.recipes__wrapper__slider');
    this.abiqSliderRelated = $('.related__wrapper__slider');
    this.abiqSliderArchive = $('.recipes__slider__content');
    this.abiqGallery = $('.gallery__slider');
    this.initSlider();
  }

  // Methods
  initSlider() {
    var that = this;

    if (that.abiqSlider.length > 0) {
      console.log('slider');
      that.abiqSlider.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>'
      })
    }

    if (that.abiqSliderIndustries.length > 0) {
      console.log('industries');
      that.abiqSliderIndustries.slick({
        infinite: true,
        speed: 4000,
        slidesToShow: 8,
        rows: 1,
        slidesToScroll: 8,
        slidesPerRow: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1, 
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      });
    }

    if (that.abiqSliderRecipes.length > 0) {
      console.log('recipes');
      that.abiqSliderRecipes.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false,
        centerPadding: '0px',
        responsive: [
          {
            breakpoint: 860,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }

    if (that.abiqSliderRelated.length > 0) {
      console.log('related');
      that.abiqSliderRelated.slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false,
        centerPadding: '0px',
        responsive: [
          {
            breakpoint: 860,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }

    if (that.abiqSliderArchive.length > 0) {
      console.log('recipes archive');
      that.abiqSliderArchive.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: false,
        centerPadding: '0px',
        repeat: false,
        adaptiveHeight: true
      });
    }

    if (that.abiqGallery.length > 0) {
      console.log('gallery');
      that.abiqGallery.slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: true,
        autoplay: true,
        centerPadding: '0px',
        repeat: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 860,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
      });
    }

    if (that.abiqSliderAssocietes.length > 0) {
      console.log('associates');
      that.abiqSliderAssocietes.slick({
        infinite: true,
        speed: 4000,
        slidesToShow: 8,
        rows: 1,
        slidesToScroll: 8,
        slidesPerRow: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1, 
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      });
    }

  }

}