import $ from 'jquery';

export default class Dropdown {
  constructor() {
    this.menu = $('.menu-dropdown');
    this.events();
    }

  events(){
    if(this.menu) {
        this.menu.on('click', function(event) {
            $(this).toggleClass('open');
            event.stopPropagation(); 
        });
    }
  }

}